
export const thirdYear = [
    {
        "url": "https://live.staticflickr.com/65535/52965821941_f27650736c_n.jpg",
        "name": "Aman Singh",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/_amansingh1007_?r=nametag",
        "linkedinLink": "https://www.linkedin.com/in/aman-singh-16273727a",
    },
    {
        "url": "https://live.staticflickr.com/65535/53127098654_daac794073_m.jpg",
        "name": "Aniruddha Roy",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "https://www.linkedin.com/in/aniruddha-roy-b3b233261",
    },
    {
        "url": require('../Assets/3rdYearImg/Aryan Gupta.png'),
        "name": "Aryan Gupta",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/aryangupta_25?igsh=OGU2b3RsOWdxbTkw",
        "linkedinLink": "https://www.linkedin.com/in/aryangupta2511/",
    },
    {
        "url": "https://live.staticflickr.com/65535/52965889164_af7b692f0f_n.jpg",
        "name": "Anubhav Mondal",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/anubhav.mondal.315?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "https://www.linkedin.com/in/anubhav-mondal-61b963255",
    },
    {
        "url": require('../Assets/3rdYearImg/Chandan Kumar Roy.png'),
        "name": "Chandan Roy",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/prash_482/",
        "linkedinLink": "https://www.linkedin.com/in/prasant-sahu-0b236923a",
    },

    {
        "url": "https://live.staticflickr.com/65535/52966207433_cb91a81c21_n.jpg",
        "name": "Doki Manoj Kumar",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/manoj_doki?igshid=ZDc4ODBmNjlmNQ==",
        "linkedinLink": "https://www.linkedin.com/in/manoj-kumar-doki-796549250/",
    },
    {
        "url": require('../Assets/3rdYearImg/Devansh Dubey.png'),
        "name": "Devansh Dubey",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966207158_d929d620a0_n.jpg",
        "name": "Debjit Banerjee",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/dev_b0073?igshid=NGExMmI2YTkyZg==",
        "linkedinLink": "https://www.linkedin.com/in/debjit-banerjee-88b49526b",
    },
    {
        "url": "https://live.staticflickr.com/65535/52965150222_77d6eca948_w.jpg",
        "name": "Harshit Shukla",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "",
    },
    // {
    //     "url":"https://live.staticflickr.com/65535/52965744206_776ec301a1_n.jpg",
    //     "name":"Lochan Vaishnav",
    //     "position":"Senior Coordinator",
    //     "instaLink":"",
    //     "linkedinLink":"",
    // },
    {
        "url": require("./3rdYearImg/Mayank Mani Singh.png"),
        "name": "Mayank Mani Singh",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/singhmayankmani?igshid=ZDc4ODBmNjlmNQ==",
        "linkedinLink": "https://www.linkedin.com/in/mayank-mani-singh-aa133526a",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966124710_7974bb9c26_w.jpg",
        "name": "Manish Kumar Yadav",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966124605_06b5472610_w.jpg",
        "name": "Namrata Gupta",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/me_namrataax?igsh=NzRiNDB5N2VvaWUz",
        "linkedinLink": "https://www.linkedin.com/in/namrata-gupta-b22b74259/",
    },
    {
        "url": require("./3rdYearImg/Nisarga Jana.png"),
        "name": "Nisarga Jana",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/nj_0.0_?igshid=NGExMmI2YTkyZg==",
        "linkedinLink": "https://www.linkedin.com/in/nisarga-jana-634400259",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966124865_83437e845b_w.jpg",
        "name": "Nishkarsh Chowdary",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/nishkarsh_choudhary_3604?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "",
    },
    {
        "url": require("./3rdYearImg/Kiranmai Perada.png"),
        "name": "Perada Kiranmai",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/__.k.i.r.a.n.m.a.i.__?igshid=NGExMmI2YTkyZg==",
        "linkedinLink": "https://www.linkedin.com/in/kiranmai-perada-a606b226a",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966361968_cbdb745c7f_n.jpg",
        "name": "Pothina Divya Venkata Durga Naga Sai",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "https://www.linkedin.com/in/pothina-divya-venkata-durga-naga-sai-45610625a",
    },
    {
        "url": require("./3rdYearImg/Charan Teja.png"),
        "name": "Pochepalli Charan Teja",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/urstrulycharanteja/",
        "linkedinLink": "https://www.linkedin.com/in/charanteja-pochepalli-274856257/",
    },

    {
        "url": require("../Assets/3rdYearImg/Raman Patel.png"),
        "name": "Raman Patel",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "https://www.linkedin.com/in/raman-patel-4a333926a",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966206688_b6a6bbbc7d_w.jpg",
        "name": "Rigden Bhutia",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/rigden._bhutia?igshid=NTc4MTIwNjQ2YQ==",
        "linkedinLink": "https://www.linkedin.com/in/rigden-bhutia-6596ba250",
    },
    {
        "url": require('../Assets/3rdYearImg/Rituparna Pradhan.png'),
        "name": "Rituparna Pradhan",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/ritu_parna8?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "https://www.linkedin.com/in/rituparna-pradhan-96774a26a",
    },
    {
        "url": "https://live.staticflickr.com/65535/52965888754_4f08ca4c58_w.jpg",
        "name": "Samaseen Prabhat",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/ritu_parna8?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "https://www.linkedin.com/in/samaseen-prabhat-b90909262",
    },
    {
        "url": require("../Assets/3rdYearImg/Sarvapalli Arya.png"),
        "name": "Sarvpalli Arya",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/shivangi_._queen?igshid=NGExMmI2YTkyZg==",
        "linkedinLink": "https://www.linkedin.com/in/sarvpalli-arya-0034a9261",
    },
    {
        "url": require("./3rdYearImg/Soumya Raj.png"),
        "name": "Soumya Raj",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/_sxumyaa?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "https://www.linkedin.com/in/soumya-raj-78685727a",
    },
    {
        "url": "https://live.staticflickr.com/65535/53126313107_106838b187_t.jpg",
        "name": "Tarak Suhas Abhiram",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "https://in.linkedin.com/in/tarak-suhas-abhiram-puppala-418b25268",
    },
    {
        "url": require("./3rdYearImg/Tanmay Bandhu Jaiswal.png"),
        "name": "Tanmay Bandhu Jaiswal",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/t.a.n.m.a.y._.j.a.i.s.w.a.l?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "https://www.linkedin.com/in/tanmay-bandhu-jaiswal-552b31229",
    },
    {
        "url": "https://live.staticflickr.com/65535/52965149777_eae9508a6d_w.jpg",
        "name": "Susmitha Khatun",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/susmitakhatun192?igshid=MzNlNGNkZWQ4Mg==",
        "linkedinLink": "https://www.linkedin.com/in/susmita-khatun-b48a6b251",
    },
    {
        "url": "https://live.staticflickr.com/65535/52965888629_b33d98f268_n.jpg",
        "name": "Suresh Chandra Adhikari",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/suresh2441?igsh=MWZ2djNqbG5ra2g0bQ==",
        "linkedinLink": "https://www.linkedin.com/in/suresh-adhikari-69b129269",
    },
    {
        "url": "https://live.staticflickr.com/65535/52965149787_0bdfa960f6_n.jpg",
        "name": "Sunil Kumawat",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966124400_54e6aa06f2_n.jpg",
        "name": "Sumantha Baskey",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/sumantabaskeybabu?r=nametag",
        "linkedinLink": "https://www.linkedin.com/in/sumanta-baskey-967093278",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966124395_f2a5cccfe8_n.jpg",
        "name": "Snehashish Sha",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "https://www.linkedin.com/in/snehashis-sha-06833726a",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966207018_ea112ec72c_n.jpg",
        "name": "Souryadipta Khan",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966124380_bbb90e0987_w.jpg",
        "name": "Yashasree",
        "position": "Senior Coordinator",
        "instaLink": "https://instagram.com/_yashasree_?igshid=ZDc4ODBmNjlmNQ==",
        "linkedinLink": "https://www.linkedin.com/in/yashasree-appikonda-26125127b",
    },
    {
        "url": "https://live.staticflickr.com/65535/52966206483_caf3d8fbc0_w.jpg",
        "name": "Vishnu Vardhan",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "",
    },
    {
        "url": require("../Assets/3rdYearImg/Uday Bhaskar.png"),
        "name": "Uday Bhaskar",
        "position": "Senior Coordinator",
        "instaLink": "",
        "linkedinLink": "https://www.linkedin.com/in/uday-bhaskar-478392264",
    },
    {
        "url": require("../Assets/3rdYearImg/Indrajit Das.jpg"),
        "name": "Indrajit Das",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/__dreeze?igsh=MWZzbzVmMWhkbms1bA==",
        "linkedinLink": "https://www.linkedin.com/in/indrajit-das-321440245/",
    },
    {
        "url": require("../Assets/3rdYearImg/Arjya Dutta.jpg"),
        "name": "Arjya Dutta",
        "position": "Senior Coordinator",
        "instaLink": "https://www.instagram.com/10_arjya_d?igsh=MWtpZW5vNmN6bDNkdw==",
        "linkedinLink": "https://www.linkedin.com/in/arjya-dutta-356449275/",
    },
    
]
